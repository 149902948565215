import { cva } from 'class-variance-authority';

export const popover = cva([
  'shadow-8',
  'flex',
  'flex-col',
  'overflow-hidden',
  'rounded-2xl',
  'bg-white',
  'z-[999]',
  'max-w-xs',
  'md:max-w-none',
]);
